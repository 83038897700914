import React from "react"
import './results.styles.scss'
import { Link } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { format } from 'date-fns'
import { UseCloudFunction } from '@/firebase-utils/firebase.utils'
import UIkit from 'uikit'

const Results = ({ matchups }) => {

    const currentGame = useSelector(state => state.gameData.currentGame);
    const userProfile = useSelector(state => state.userData.profile);
	const dispatch = useDispatch()
	
	const saveWinner = async (matchId, winner) => {
		console.log(winner)
		let data = {
            appId: userProfile.appId,
			gameId: currentGame.gameId,
			matchupId: matchId,
			winner: winner,
			comp1Details: document.getElementById(`comp1Details-${matchId}`).value,
            comp2Details: '',
			ts: new Date().getTime()
		}
        if (matchups[matchId].type === 'matchup') {
            data.comp2Details = document.getElementById(`comp2Details-${matchId}`).value;
        }
		const results = await UseCloudFunction('saveScores', data)
		console.log(results)
		UIkit.modal.alert('Scores saved and leaderboard is updated!')
	}

    async function sendUpdateMessage() {
        const data = {
            'title': 'The leaderboard has been updated!',
            'text': 'Check the leaderboard! The scores have been recently updated.',
            'category': 'general',
            'gameId': currentGame.gameId,
            'ts': new Date().getTime(),
            'email': profile.email,
            'icon': currentGame.icon
        }
        const res = await UseCloudFunction('sendMessage', {'content': data})
        console.log(res);
        UIkit.modal.alert("Update message sent.");
    }
	
	return (
		<div className='results'>
			<Link to='/gameadmin'>
				<button className='g-button'>Back</button>
			</Link>
            <button className='g-button' onClick={() => sendUpdateMessage()}>Send Updated Leaderboard Message</button>
			<div className='results-list'>
				{
					(matchups) &&
					Object.values(matchups)
                    .sort((a,b) => (a.date > b.date) ? 1 : -1)
                    .map(match => {
                        if (match.winner) return;
                        if (match.type === 'matchup') 
                        return (
                            <div key={match.id} className='result-box g-box'>
                                <table>
                                    <tbody>
                                        <tr>
                                            <td className={(match.winner && match.winner === 'comp1') ? 'matchup-img-td picked' : 'matchup-img-td'} id={`comp1${match.id}`}>
                                                <img src={match.comp1Icon} className='matchup-img' alt={`${match.comp1} logo`} loading='lazy' />
                                                <span>{match.comp1}</span>
                                                <br />
                                                <button className='g-button' onClick={() => saveWinner(match.id, 'comp1')}>W</button>
                                                {
                                                    (match.type === 'matchup') &&
                                                    <div className="uk-margin">
                                                        <input className="uk-input uk-form-small" type="text" defaultValue={(match.comp1Details) ? match.comp1Details : ''} id={`comp1Details-${match.id}`} placeholder='Details' />
                                                    </div>
                                                }
                                            </td>
                                            <td className='matchup-text-td'>
                                                <b>{match.name}</b>
                                                <br />
                                                {match.desc}
                                                <br />
                                                {(match.date) ? format(new Date(match.date), "EEE, L/d/y h:mm bbb") : ''}
                                                {
                                                    (match.type === 'prop') &&
                                                    <div className="uk-margin">
                                                        <input className="uk-input uk-form-small" type="text" defaultValue={(match.comp1Details) ? match.comp1Details : ''} id={`comp1Details-${match.id}`} placeholder='Details' />
                                                    </div>
                                                }
                                            </td>
                                            <td className={(match.winner && match.winner === 'comp2') ? 'matchup-img-td picked' : 'matchup-img-td'} id={`comp2${match.id}`}>
                                                <img src={match.comp2Icon} className='matchup-img' alt={`${match.comp2} logo`} loading='lazy' />
                                                <span>{match.comp2}</span>
                                                <br />
                                                <button className='g-button' onClick={() => saveWinner(match.id, 'comp2')}>W</button>
                                                {
                                                    (match.type === 'matchup') &&
                                                    <div className="uk-margin">
                                                        <input className="uk-input uk-form-small" type="text" defaultValue={(match.comp2Details) ? match.comp2Details : ''} id={`comp2Details-${match.id}`} placeholder='Details' />
                                                    </div>
                                                }
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        )
                        else if (match.type === 'prop')
                        return (
                            <div 
                                key={match.id} 
                                className='result-box g-box prop-box'>
                                <div><b>{match.name}</b></div>
                                <div>{match.desc}</div>
                                <div>{format(new Date(match.date), "EEE, L/d/y h:mm bbb")}</div>
                                <div className="g-space-1"></div>
                                <div className="details-div">
                                    <input 
                                        className="uk-input uk-form-small" 
                                        type="text" 
                                        defaultValue={
                                            (match.comp1Details) 
                                            ? match.comp1Details 
                                            : ''
                                        } 
                                        id={`comp1Details-${match.id}`} 
                                        placeholder='Details' />
                                </div>
                                <div className="comp-list">
                                {
                                    [1,2,3,4,5].map(e => {
                                        const path = `comp${e}`;
                                        if (!match[path]) return;
                                        return (
                                            <button 
                                                className="g-button comp-item"
                                                onClick={() => saveWinner(match.id, path)}>
                                                {match[path]}
                                            </button>
                                        )
                                    })
                                }
                                </div>
                                <div className="g-space-1"></div>
                            </div>
                        )
                    })
				}
                <div className="results-head-box">
                    <h3>Completed Matchups</h3><hr />
                </div>
                {
					(matchups) &&
					Object.values(matchups)
                    .sort((a,b) => (a.date < b.date) ? 1 : -1)
                    .map(match => (
                        match.winner &&
                        <div key={match.id} className='result-box g-box'>
                            <table>
                                <tbody>
                                    <tr>
                                        <td className={(match.winner && match.winner === 'comp1') ? 'matchup-img-td picked' : 'matchup-img-td'} id={`comp1${match.id}`}>
                                            <img src={match.comp1Icon} className='matchup-img' alt={`${match.comp1} logo`} loading='lazy' />
                                            <span>{match.comp1}</span>
                                            <br />
                                            <button className='g-button' onClick={() => saveWinner(match.id, 'comp1')}>W</button>
                                            <div className="uk-margin">
                                                <input className="uk-input uk-form-small" type="text" defaultValue={(match.comp1Details) ? match.comp1Details : ''} id={`comp1Details-${match.id}`} placeholder='Details' />
                                            </div>
                                        </td>
                                        <td className='matchup-text-td'>
                                            <b>{match.name}</b>
                                            <br />
                                            {match.desc}
                                            <br />
                                            {(match.date) ? format(new Date(match.date), "EEE, L/d/y h:mm bbb") : ''}
                                        </td>
                                        <td className={(match.winner && match.winner === 'comp2') ? 'matchup-img-td picked' : 'matchup-img-td'} id={`comp2${match.id}`}>
                                            <img src={match.comp2Icon} className='matchup-img' alt={`${match.comp2} logo`} loading='lazy' />
                                            <span>{match.comp2}</span>
                                            <br />
                                            <button className='g-button' onClick={() => saveWinner(match.id, 'comp2')}>W</button>
                                            <div className="uk-margin">
                                                <input className="uk-input uk-form-small" type="text" defaultValue={(match.comp2Details) ? match.comp2Details : ''} id={`comp2Details-${match.id}`} placeholder='Details' />
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    ))
				}
			</div>
		</div>
	)
}

export default Results