import fbl from "@/assets/icons/ftbl.png";
import tourn from "@/assets/icons/tourn.png";
import stadium from "@/assets/icons/stadium.png";
import cfbplff from "@/assets/icons/cfbplyoff.png";
import nfl from "@/assets/icons/nfl.png";
import basketball from "@/assets/icons/basketball.png";
import trophy from "@/assets/icons/trophy.png";
import volleyball from "@/assets/icons/volleyball.png";

export const categories = {
    "bowl": {
        "id": "bowl",
        "title": "Bowl Game",
        "icon": stadium
    },
    "cfp": {
        "id": "cfp",
        "title": "College Football Playoff",
        "icon": cfbplff
    },
    "fcs": {
        "id": "fcs",
        "title": "FCS Tournament",
        "icon": tourn
    },
    "cfb": {
        "id": "cfb",
        "title": "College Football",
        "icon": fbl
    },
    "nfl": {
        "id": "nfl",
        "title": "NFL Football",
        "icon": nfl
    },
    "cbb": {
        "id": "cbb",
        "title": "College Basketball",
        "icon": basketball
    },
    "mm": {
        "id": "mm",
        "title": "March Madness",
        "icon": tourn
    },
    "soc": {
        "id": "soc",
        "title": "Soccer",
        "icon": tourn
    },
    "wcs": {
        "id": "wcs",
        "title": "World Cup",
        "icon": trophy
    },
    "vb": {
        "id": "vb",
        "title": "NCAA Volleyball",
        "icon": volleyball
    },
}