import React, { useEffect, useRef, useState } from 'react'
import './new-matchup.styles.scss'
import { useSelector, useDispatch } from 'react-redux'

import flatpickr from 'flatpickr'
import { UseCloudFunction } from '@/firebase-utils/firebase.utils'
import UIkit from 'uikit'
import { categories } from '@/utils/matchup-categories'

const NewMatchup = ({ matchup, competitors }) => {

	const currentGame = useSelector(state => state.gameData.currentGame)
	const [ formData, setFormData ] = useState({})
    const stateRef = useRef();
	const dispatch = useDispatch();
    let fp1Ref = useRef();
	
	useEffect(() => {
        if (!matchup || !fp1Ref) return;
        console.log(matchup)
        stateRef.current = {...matchup}
		setFormData(stateRef.current);
        if (fp1Ref.current) {
            fp1Ref.current.setDate(new Date(matchup.date));
        }
	}, [matchup, fp1Ref]);

    useEffect(() => {
        stateRef.current = {}
        const date1Element = document.getElementById('date');
		fp1Ref.current = flatpickr(date1Element, 
			{
				altInput: true,
	            altFormat: "F j, Y, h:i K",
	            enableTime: true,
	            dateFormat: "Y-m-d h:i K",
				onChange: (dates) => {saveData('date', new Date(dates[0]).getTime())}
			}
		);
    }, [])
	
	const saveData = (key, data) => {
		stateRef.current = {...stateRef.current, ...{[key]: data}};
        setFormData(stateRef.current);
		console.log(stateRef.current)
	}

	const saveCompetitorToMatchup = (key, data) => {
		const comp = competitors[data]
		console.log(comp);
        stateRef.current = {...stateRef.current, ...{ [key]: comp.name, [`${key}Icon`]: comp.icon, [`${key}Id`]: comp.id }}
		setFormData(stateRef.current);
	}

	function cancelSave() {
		
		window.scrollTo(0,0)
		fp1Ref.current.clear();
        stateRef.current = {}
        setFormData(stateRef.current)
	}

	const handleSubmit = async (e) => {
		e.preventDefault()
        const tempData = {...stateRef.current}
		
        const now = new Date().getTime();
        tempData.ts = now;
		if (!tempData.id) {
			tempData.id = now + '_matchup'
            tempData.ts_created = now;
            tempData.gameId = currentGame.gameId;
            tempData.status = 'active';
            tempData.type = 'matchup'
		}
        console.log(tempData)
		const res = await UseCloudFunction('saveMatchup', {'matchup': tempData})
        if (res.error) {
            UIkit.modal.alert('Something went wrong: ' + res.error);
            return;
        }
        UIkit.modal.alert('Matchup saved!');
        fp1Ref.current.clear();
        stateRef.current = {};
        setFormData(stateRef.current);
		document.getElementById('new-matchup-col').scrollIntoView({'behavior':'smooth'})
	}
	
	return (

		<div className='new-matchup'>
			<div className='g-box'>
				<form onSubmit={handleSubmit}>
                    <div className="uk-margin">
						<label>Game Name</label>
						<input type="text" className="uk-input" value={formData.name ?? ''} 
							onChange={e => saveData('name', e.target.value)} />
					</div>
					<div className="uk-margin">
						<label>Stadium Name</label>
						<input type="text" className="uk-input" value={formData.stadium ?? ''} 
							onChange={e => saveData('stadium', e.target.value)} />
					</div>
					<div className="uk-margin">
						<label>Location</label>
						<input type="text" className="uk-input" value={formData.location ?? ''} 
							onChange={e => saveData('location', e.target.value)} />
					</div>
					<div className="uk-margin">
						<label>Date & Time</label>
						<input className="uk-input flatpickr flatpickr-input" type="text" id="date" />
					</div>
					<div className="uk-margin">
						<label>TV Listings</label>
						<input type="text" className="uk-input" value={formData.tv ?? ''} 
							onChange={e => saveData('tv', e.target.value)} />
					</div>
					<div className="uk-margin">
						<label>Game Category</label>
						<select className="uk-select" value={formData.category ?? ''} 
								onChange={e => saveData('category', e.target.value)}>
							<option value="">Choose a Game Category</option>
							{
								(categories) &&
								Object.values(categories).map(c => (
									<option key={c.id} value={c.id}>{c.title}</option>
								))
							}
						</select>
					</div>
					<div className="uk-margin">
						<label>Wager Level</label>
						<select className="uk-select" value={formData.wager ?? ''} 
								onChange={e => saveData('wager', e.target.value)}>
							<option value="">Choose a Wager Level</option>
							<option value="wager1">100-500</option>
							<option value="wager2">300-700</option>
							<option value="wager3">500-900</option>
							<option value="wager4">800-1,200</option>
						</select>
					</div>
					<div className="uk-margin">
						<label>
							<input className="uk-checkbox" type="checkbox" checked={formData.penalty ?? false}
								onChange={e => saveData('penalty', e.target.checked)} />
								 Penalize player for not picking?
						</label>
					</div>
					<div className="uk-margin">
						<label>Competitor 1</label>
						<select className="uk-select" value={formData.comp1Id ?? ''} 
							onChange={e => saveCompetitorToMatchup('comp1', e.target.value)}>
							<option value="">Choose a Competitor</option>
							{
								(competitors) 
								?  
								Object.values(competitors)
								.sort((a,b) => (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : -1)
								.map(comp => (
									<option key={comp.id} value={comp.id}>{comp.name}</option>	
								))
								: ''
							}
						</select>
					</div>
					<div className="uk-margin">
						<label>Competitor 1 Rank (optional)</label>
						<input type="text" className="uk-input" value={formData.comp1Rank ?? ''} 
							onChange={e => saveData('comp1Rank', e.target.value)} />
					</div>
					<div className="uk-margin">
						<label>Competitor 1 Info (optional)</label>
						<input type="text" className="uk-input" value={formData.comp1Info ?? ''} 
							onChange={e => saveData('comp1Info', e.target.value)} />
					</div>
					<div className="uk-margin">
						<label>Competitor 2</label>
						<select className="uk-select" value={formData.comp2Id ?? ''} 
							onChange={e => saveCompetitorToMatchup('comp2', e.target.value)}>
							<option value="">Choose a Competitor</option>
							{
								(competitors) 
								?  
								Object.values(competitors)
								.sort((a,b) => (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : -1)
								.map(comp => (
									<option key={comp.id} value={comp.id}>{comp.name}</option>	
								))
								: ''
							}
						</select>
					</div>
					<div className="uk-margin">
						<label>Competitor 2 Rank (optional)</label>
						<input type="text" className="uk-input" value={formData.comp2Rank ?? ''} 
							onChange={e => saveData('comp2Rank', e.target.value)} />
					</div>
					<div className="uk-margin">
						<label>Competitor 2 Info (optional)</label>
						<input type="text" className="uk-input" value={formData.comp2Info ?? ''} 
							onChange={e => saveData('comp2Info', e.target.value)} />
					</div>
					<div className='new-competitor-buttons'>
						<button className='g-button small-pad' type='button' onClick={() => cancelSave()}>Cancel</button>
						<button className='g-button no-pad' type='submit' style={{'backgroundColor':'#03C04A'}} >Save</button>
					</div>
                </form>
			</div>
		</div>
	)
}

export default NewMatchup