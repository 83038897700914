import { db, rtDb } from "@/firebase-utils/firebase.utils";
import { query as fbQuery, collection, doc, onSnapshot, getDoc, and, where, limit, or } from "firebase/firestore";
import { onValue, ref } from "firebase/database";

export async function getGamesFromDb(e) {
    let gamesRef = fbQuery(collection(db, 'registrations'), and(where('status', '==', 'active'), or(where('list', 'array-contains', e.appId), where('public', '==', true))));

    const unsubscribe = onSnapshot(gamesRef, docs => {
        docs.forEach(async doc => {
            let gameData = doc.data();
            e.callback(gameData);
        })
    })
    e.handleListener(unsubscribe);
}


export async function getMatchupsFromDb(e) {
    let matchRef;

    if (e.gameId) {
        matchRef = fbQuery(collection(db, 'matchups'), where('gameId', '==', e.gameId))
    }

    const unsubscribe = onSnapshot(matchRef, docs => {
        docs.forEach(async doc => {
            let matchData = doc.data();
            e.callback(matchData);
        });
    })
    e.handleListener(unsubscribe);
    return;
}

export async function getUniqueDocFromDb(e) {
    let docRef;

    if (e.collection && e.docId) {
        docRef = doc(db, e.collection, e.docId)
    }

    if (e.once) {
        const docData = await getDoc(docRef)
        e.callback(docData.data());
        return;
    } else {
        const unsubscribe = onSnapshot(docRef, doc => {
            e.callback(doc.data())
        })
        e.handleListener(unsubscribe);
        return;
    }
}

export async function getQueryFromDb(e) {
    let queryRef;
    if (e.collection && e.gameId && e.status) {
        queryRef = fbQuery(collection(db, e.collection), and(where('gameId', '==', e.gameId), where('status', '==', e.status)));
    }
    else if (e.collection && e.gameId && e.key1 && e.value1) {
        queryRef = fbQuery(collection(db, e.collection), and(where('gameId', '==', e.gameId), where(e.key1, '==', e.value1)));
    }
    else if (e.collection && e.gameId) {
        queryRef = fbQuery(collection(db, e.collection), where('gameId', '==', e.gameId));
    }
    else if (e.collection && e.gameId && e.status && e.startDate) {
        queryRef = fbQuery(collection(db, e.collection), and(where('gameId', '==', e.gameId), where('status', '==', e.status), where('ts', '<', e.startDate)));
    }
    const unsubscribe = onSnapshot(queryRef, docs => {
        docs.forEach(async doc => {
            let queryData = doc.data();
            e.callback(queryData);
        });
    })
    e.handleListener(unsubscribe);
    return;

}

export async function getDataFromRtDb(e) {
    let dataRef;
    if (e.path) {
        dataRef = ref(rtDb, e.path)
    }

    onValue(dataRef, (snapshot) => {
        const data = snapshot.val();
        e.callback(data);
    });
    e.handleListener(dataRef);
}

/**
 * if (e.gameId) {
        gameRef = doc(db, 'games', e.gameId);
    } else if (e.joinCode){
        gameRef = fbQuery(collection(db, 'games'), and(where('joinCode', '==', e.joinCode), where('status', '==', e.status)))
    } else if (e.gamePath) {
        gameRef = fbQuery(collection(db, 'games'), and(where('path', '==', e.gamePath), where('status', '==', e.status)))
    }

    if (e.once) {
        const gameData = await getDoc(gameRef)
        e.callback(gameData.data());
        return;
    } else {
        const unsubscribe = onSnapshot(gameRef, docs => {
            docs.forEach(async doc => {
                let gameData = doc.data();
                e.callback(gameData);
            });
        })
        e.handleListener(unsubscribe);
    }
 */