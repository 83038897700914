import React, { useEffect, useRef, useState } from 'react'
import './manage-users.styles.scss'

import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import { rtDb, UseCloudFunction } from '@/firebase-utils/firebase.utils'
import { child, get, ref, set } from 'firebase/database'
import { setLeaders } from '@/state/slices/gameSlice'
import Leaders from '@/components/leaders/leaders.component'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowAltCircleRight, faSave, faTrash } from '@fortawesome/free-solid-svg-icons'
import UIkit from 'uikit'
import { getUniqueDocFromDb } from '@/utils/getDataFromDb'

const ManageUsers = () => {

    const currentGame = useSelector(state => state.gameData.currentGame);
    const [ leaders, setLeaders ] = useState(null);
    const [ notes, setNotes ] = useState({})
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const notesRef = useRef({});
    let leadersListener;
    let notesListener;

    useEffect(() => {

        return () => {
            if (leadersListener) leadersListener();
            if (notesListener) notesListener();
        }
    }, [])

    useEffect(() => {
        if (!currentGame) return;
        getLeaders();
        getNotes();
    }, [currentGame])

    function getLeaders() {
        getUniqueDocFromDb({'collection': 'leaders', 'docId': currentGame.gameId, 'callback': callback, 'handleListener': handleListener})
        function callback(data) {
            setLeaders(data);
        }
        function handleListener(unsub) {
            leadersListener = unsub;
        }
    }

    function getNotes() {
        getUniqueDocFromDb({
            'collection': 'userNotes',
            'docId': currentGame.gameId,
            'callback': callback,
            'handleListener': handleListener
        })
        function callback(data) {
            notesRef.current = {
                ...notesRef.current,
                ...data
            }
            setNotes(notesRef.current);
        }
        function handleListener(unsub) {
            notesListener = unsub;
        }
    }

    function saveNote(appId, note) {
        notesRef.current = {
            ...notesRef.current,
            ...{[appId]: note}
        }
        setNotes(notesRef.current);
    };

    async function saveNoteToDb() {
        let tempNotes = {...notesRef.current};
        const res = await UseCloudFunction(
            'saveUserNote',
            {
                'gameId': currentGame.gameId,
                'noteDoc': tempNotes
            }
        )
        console.log(res);
    }

    return (
        <div className='manage-users'>
            <div>
                <Link to='/gameadmin'>
                    <button className='g-button'>Back</button>
                </Link>
            </div>
            <div className="g-grid">
                <div className="g-col user-list">
                    <table className='g-table'>
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Notes</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                        {
                            leaders && notes &&
                            Object.values(leaders).sort((a, b) => 
                                (a.lName > b.lName) 
                                ? 1 
                                : (a.lName === b.lName) 
                                ? ((a.fName > b.fName) ? 1 : -1) 
                                : -1 
                            )
                            .map(user => (
                                <tr key={user.appId} className='user-item'>
                                    <td>{user.fName} {user.lName}</td>
                                    <td>
                                        <input 
                                            className='uk-input' 
                                            value={
                                                notes[user.appId] 
                                                ?? ''
                                            } 
                                            type='text' 
                                            id={`notes-${user.appId}`} 
                                            onChange={(e) => saveNote(user.appId, e.target.value)}
                                            onBlur={() => saveNoteToDb()} />
                                    </td>
                                    <td className='icon-td'>
                                        <div className='icons'>
                                            <FontAwesomeIcon 
                                                icon={faTrash} 
                                                className='clickable' />
                                            <FontAwesomeIcon 
                                                icon={faArrowAltCircleRight} 
                                                className='clickable' 
                                                onClick={() => navigate(user.appId)} />
                                        </div>
                                    </td>
                                </tr>
                            ))
                            
                        }
                        </tbody>
                    </table>
                    
                </div>
                {/* <div className="g-half-col leaders-list">
                    <Leaders />
                </div> */}
            </div>
                
        </div>
    )
}

export default ManageUsers