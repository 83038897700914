import React, { useEffect, useState } from 'react'
import './user-info-col.styles.scss'
import { useSelector } from 'react-redux'
import banner_no_logo from '@/assets/banner-no-logo.png';
import { sortLeaders } from '@/utils/sortLeaders';

const UserInfoCol = ({ leaders, matchups, userId }) => {

	const userProfile = useSelector(state => state.userData.profile)
	const [ isAdmin, setIsAdmin ] = useState(true)
	const [ profile, setProfile ] = useState(null)
    const [ sortedLeaders, setSortedLeaders ] = useState(null);
    const [ picks, setPicks ] = useState(null); 
	const [ optionalPicks, setOptionalPicks ] = useState(null);
    const [ totalPoints, setTotalPoints ] = useState(0);
    const [ picksMade, setPicksMade ] = useState(0);
	const [ optionalPicksMade, setOptionalPicksMade ] = useState(0);
    const [ totalMatches, setTotalMatches ] = useState(0);
	const [ requiredMatches, setRequiredMatches ] = useState(0);
    const [ correctPicks, setCorrectPicks ] = useState(0);
    const [ completedPicks, setCompletedPicks ] = useState(0);
    const [ totalEarned, setTotalEarned ] = useState(0);
    const [ totalLost, setTotalLost ] = useState(0);
    const [ matchupsRemaining, setMatchupsRemaining ] = useState(0);
    const [ rank, setRank ] = useState('');
    const [ path ] = useState(window.location.pathname)
	
	useEffect(() => {
		setIsAdmin(window.location.href.includes('/gameadmin') || window.location.href.includes('/home'))
	}, [])

	useEffect(() => {
		if (!leaders) return;
        setSortedLeaders(sortLeaders(leaders));
	}, [leaders])

    useEffect(() => {
        if (!sortedLeaders || !userProfile) return;
        let appId;
        if (userId) {
            appId = userId;
        } else {
            appId = userProfile.appId;
        }
        const pro = sortedLeaders.filter(l => l.appId === appId)[0]
        setProfile(pro);
        setTotalPoints((pro.matchPoints + pro.propPoints + pro.bonusPoints).toLocaleString());
        setRank(`${sortedLeaders.findIndex(leader => leader.appId === pro.appId) + 1} / ${sortedLeaders.length}`);
    }, [sortedLeaders, userProfile, userId])

    useEffect(() => {
        if (!matchups || !userProfile || !sortedLeaders) return;
        let appId;
        if (userId) {
            appId = userId;
        } else {
            appId = userProfile.appId;
        }
        const ps = Object.values(matchups).filter(m => m.type === 'matchup' && m.penalty && m.picks && m.picks[appId]).map(m => m.picks[appId]);
        // console.log(ps);
        setPicks(ps);
        setPicksMade(ps.length);
		const ops = Object.values(matchups).filter(m => m.type === 'matchup' && !m.penalty && m.picks && m.picks[appId]).map(m => m.picks[appId]);
		setOptionalPicks(ops);
		setOptionalPicksMade(ops.length);
        setTotalMatches(Object.values(matchups).filter(m => 
            m.type === 'matchup'
        ).length);
		setRequiredMatches(Object.values(matchups).filter(m => 
            m.type === 'matchup' &&
			m.penalty
        ).length);
        setCorrectPicks(Object.values(matchups).filter(m => 
            m.type === 'matchup' && 
            m.picks &&
            m.picks[appId] &&
            m.picks[appId].pick === m.winner
        ).length)
        setCompletedPicks(Object.values(matchups).filter(m => 
            m.type === 'matchup' && 
            m.picks &&
            m.picks[appId] &&
            m.winner
        ).length)
        setTotalEarned(Object.values(matchups).filter(m => m.winner && m.picks && m.picks[appId] && m.picks[appId].pick === m.winner)
        .reduce((acc, m) => acc + m.picks[appId].wager, 0) + sortedLeaders.filter(l => l.appId === appId)[0].bonusPoints)
        setTotalLost(Object.values(matchups).filter(m => m.winner && m.picks && m.picks[appId] && m.picks[appId].pick !== m.winner)
        .reduce((acc, m) => acc + m.picks[appId].wager, 0))
        setMatchupsRemaining(Object.values(matchups).filter(m => 
            m.type === 'matchup' &&
            !m.winner
        ).length);
    }, [matchups, userProfile, userId, sortedLeaders])
	
	return (
		<div className='user-info'>
			{
				(profile) ?

				<div>
					<img className='user-icon' id='user-info-col-user-icon' referrerPolicy="no-referrer" src={profile.icon} alt={`${profile.fName} ${profile.lName}'s user icon`} />
					<div className='spacer' />
					<span className='user-name'>{profile.fName} {profile.lName}</span>
					{
						(!isAdmin)
						?
							<div className='user-game-info'>
								<div className='spacer' />
								<span className='points-earned'>Total Points Earned</span><br/>
								<span className='points'>
									{totalPoints}
    							</span>
								<div className='spacer' />
								{
									(picks) &&
                                    <>
										<span className='picks-made'>Req. Picks Made</span><br />
										<span className='pick-count'>
											{picksMade} / {requiredMatches}</span>
										<div className='g-space-0-5' />
										<span className=''>Opt. Picks Made</span><br />
										<span className='pick-count'>
											{optionalPicksMade} / {totalMatches - requiredMatches}</span>
										<div className='spacer' />
									</>
								}
								<table className='ind-stats'>
									<tbody>
										<tr>
											<td width="50%" align="right">
												Match Points:
											</td>
											<td width="50%" align="left"> 
												<b>{profile.matchPoints.toLocaleString()}</b>
											</td>
										</tr>
										<tr>
											<td width="50%" align="right">
												Prop Points:
											</td>
											<td width="50%" align="left">
												<b>{profile.propPoints.toLocaleString()}</b>
											</td>
										</tr>
										<tr>
											<td width="50%" align="right">
												Bonus Points:
											</td>
											<td width="50%" align="left">
												<b>{profile.bonusPoints.toLocaleString()}</b>
											</td>
										</tr>
									</tbody>
								</table>
								<hr />
								<table className='ind-stats'>
									<tbody>
										<tr>
											<td width="50%" align="right">
												Rank: 
											</td>
											<td width="50%" align="left"> 
												<b>{rank}</b>
											</td>
										</tr>
										
                                        <tr>
                                            <td width="50%" align="right">
                                                Correct: 
                                            </td>
                                            <td width="50%" align="left">
                                                <b>{correctPicks > 0 && completedPicks > 0 ? ((correctPicks / completedPicks) * 100).toFixed(0) + '%' : '-'}</b>
                                            </td>
                                        </tr>
										
										{
											(userId === userProfile.appId)
											?
											<tr>
												<td width="50%" align="right">
													Tot Earned: 
												</td>
												<td width="50%" align="left">
													<b>{totalEarned}</b>
												</td>
											</tr>
											: ''
										}
										{	
											(userId === userProfile.appId)
											?
											<tr>
												<td width="50%" align="right">
													Tot Lost: 
												</td>
												<td width="50%" align="left">
													<b>-{totalLost}</b>
												</td>
											</tr>
											: ''
										}
										{
											(userId === userProfile.appId)
											?
												<tr>
													<td width="50%" align="right">
														Matchups Rem: 
													</td>
													<td width="50%" align="left">
														<b>{matchupsRemaining}</b>
													</td>
												</tr>
											: <tr><td></td></tr>
										}
										{
											(userId === userProfile.appId)
											?
												<tr>
													<td width="50%" align="right">
														Leader:
													</td>
													<td width="50%" align="left">
														{
                                                            (sortedLeaders) 
                                                            ?
                                                            <b>{sortedLeaders[0].fName} {sortedLeaders[0].lName}<br />
                                                            ({(sortedLeaders[0].matchPoints + sortedLeaders[0].propPoints + sortedLeaders[0].bonusPoints).toLocaleString()} pts)
                                                            </b>
                                                            :
                                                            'No Leader'
                                                        }
													</td>
												</tr>
											: <tr><td></td></tr>
										}
									</tbody>
								</table>
							</div>
						: ''
					}
					{
						(!userId)
						? <img className='user-logo' src={banner_no_logo} alt='poolparty logo' />
						: ''
					}
				</div>
				: ''
			}
		</div>
	)
}

export default UserInfoCol