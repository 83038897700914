import React, { useEffect, useState } from 'react';
import './matchup-list-container.styles.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarDay, faRefresh } from '@fortawesome/free-solid-svg-icons';
import ThreeDotsLoader from '@/components/loader/three-dots-loader.component';
import { MatchupItem, PassedMatchupItem } from './matchup-item.component';
import PickModal from './pick-modal.component';
import { makeLinkActive } from '@/utils/active-links';
import { useSelector } from 'react-redux';

const MatchupListContainer = ({ front, matchups }) => {

    const currentGame = useSelector(state => state.gameData.currentGame);
    const userProfile = useSelector(state => state.userData.profile);
    const [ viewing, setViewing ] = useState('upcoming');
    const [ chosenMatchup, setChosenMatchup ] = useState(null);
    const [ upcoming, setUpcoming ] = useState(null);
    const [ loading, setLoading ] = useState(true);
    const [ passed, setPassed ] = useState(null);
    const [ viewHeight, setViewHeight ] = useState(0);

    useEffect(() => {
        makeLinkActive('home-link')
        const frontContainer = document.getElementsByClassName('matchups-div');
        if (frontContainer.length > 0) {
            setViewHeight(`${frontContainer[0].clientHeight - 115}px`)
            console.log(frontContainer[0].clientHeight);
        }
    }, [])

    useEffect(() => {
        if (!matchups || !currentGame) return;
        if (matchups === 'none') {
            setUpcoming('none');
        }
        sortMatchups();
        const int = setInterval(() => {
            sortMatchups();
        }, 300000)

        return () => {
			clearInterval(int)
        }
    }, [matchups, currentGame])

    function sortMatchups() {
        let upcomingArr = []
        let passedArr = []
        const now = new Date().getTime();
        let bonus = 0;
        for (let m of Object.values(matchups).filter(m => m.type === 'matchup').sort((a,b) => a.date > b.date ? 1 : -1)) {
            if (m.date + 10800000  > now && !m.winner) {  //+ 10800000
                upcomingArr.push(m)
            } else {
                passedArr.push(m)
                if (currentGame.settings.streaks && m.picks && m.picks[userProfile.appId] && m.winner === m.picks[userProfile.appId].pick) {
                    bonus++;
                    if (bonus === currentGame.settings.streakCount) {
                        bonus = 0;
                        passedArr.push({
                            'bonus': true,
                            'matchId': m.id + '-bonus',
                            'id': m.id + '-bonus',
                            'earned': currentGame.settings.bonus,
                            'streak': currentGame.settings.streakCount,
                            'date': m.date--
                        })
                    }
                } else {
                    bonus = 0;
                }
            }
        }
        setUpcoming([...upcomingArr]);
        setPassed([...passedArr]);
        setLoading(false);
    }

    function refreshMatchups(el) {
        el.classList.add('spinning-icon')
        // getMatchups(true);
        setTimeout(() => {
            el.classList.remove('spinning-icon');
        }, 3000)
    }

    return (
        <div className='matchup-list-container page'>
            <div className={`g-box head-box`} >
				<div className='main-head'>
					<div>
                        <FontAwesomeIcon icon={faCalendarDay} className='head-icon' />
					    Matchup Picks
                    </div>
                    <FontAwesomeIcon icon={faRefresh} className='refresh-icon' onClick={(e) => {refreshMatchups(e.target)}} />
				</div>
				<hr />
                <div className='top-buttons'>
                    <button className={`g-button medium-btn ${viewing === 'upcoming' ? '' : 'inactive'}`} onClick={() => setViewing('upcoming')}>Upcoming</button>
                    <button className={`g-button medium-btn ${viewing === 'upcoming' ? 'inactive' : ''}`} onClick={() => setViewing('completed')}>Complete</button>
                </div>
                {
                    (loading)
                    ?
                    <div className='center-col'>
                        <ThreeDotsLoader />
                    </div>
                    :
                    <div className='matchup-list-content' style={{'height': viewHeight ? viewHeight : ''}}>
                        {
                            (viewing === 'upcoming')
                            ?
                            (upcoming.length > 0)
                            ?
                            upcoming.sort((a,b) => a.date > b.date ? 1 : -1)
                            .map(m => (
                                <div 
                                    key={m.id} 
                                    onClick={() => 
                                        m.comp1 && m.comp2 && !m.winner
                                        ? setChosenMatchup(m)
                                        : null
                                    }>
                                    <MatchupItem matchup={m} />
                                </div>
                            ))
                            :
                            <h5>No upcoming games...</h5>
                            :
                            (passed.length > 0)
                            ?
                            passed.sort((a,b) => a.date < b.date ? 1 : -1)
                            .map(m => {
                                return (
                                    <div key={m.id} onClick={(!m.bonus && !m.date > new Date().getTime() + 10800000) ? () => setChosenMatchup(m) : null}>
                                        <PassedMatchupItem matchup={m} />
                                    </div>
                                )
                            })
                            :
                            <h5>No completed games...</h5>
                        }
                    </div>
                }
            </div>
            <PickModal matchup={chosenMatchup} closeModal={() => setChosenMatchup(null)} /> 
        </div>
    )
}

export default MatchupListContainer